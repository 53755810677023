import * as actionTypes from "../actions/actionTypes";

const initialState = {
    loading: false,
    sent: false,
    user: null,
    error: null,
    success: null,
    userId: null,
    redirect: null,
    vehicles: [],
    persons: [],
    companies: [],
    vehicle: null,
    notificationUsers: null,
    forms: null,
    form: null,
    formImages: null,
    expenses: [],
    editExpense: null,
    largeImage: null,
    superiors: null
};

const startLoading = (state, action) => {
    return { ...state, loading: true };
};

const stopLoading = (state, action) => {
    return { ...state, loading: false };
};

const successfullySent = (state, action) => {
    return { ...state, sent: true };
};

const storeUser = (state, action) => {
    return { ...state, user: { ...action.user } };
};

const setError = (state, action) => {
    return { ...state, error: action.error };
};

const clearError = (state, action) => {
    return { ...state, error: null };
};

const setSuccess = (state, action) => {
    return { ...state, success: action.success };
};

const clearSuccess = (state, action) => {
    return { ...state, success: null };
};

const storeForms = (state, action) => {
    return { ...state, forms: [...action.forms] };
};

const storeForm = (state, action) => {
    return { ...state, form: { ...action.form } };
};

const setRedirect = (state, action) => {
    return { ...state, redirect: action.url };
};

const clearRedirect = (state, action) => {
    return { ...state, redirect: null };
};

const storeUserId = (state, action) => {
    return { ...state, userId: action.userId };
};

const storeVehicles = (state, action) => {
    return { ...state, vehicles: [...action.vehicles] };
};

const storePersons = (state, action) => {
    return { ...state, persons: [...action.persons] };
};

const storeCompanies = (state, action) => {
    return { ...state, companies: [...action.companies] };
};

const storeVehicle = (state, action) => {
    return { ...state, vehicle: { ...action.vehicle } };
};

const storeNotificationUsers = (state, action) => {
    return { ...state, notificationUsers: [...action.users] };
};

const storeImage = (state, action) => {
    return { ...state, formImages: { ...state.formImages, [action.name]: action.image } };
};

const storeExpenses = (state, action) => {
    return { ...state, expenses: [...action.data] };
};

const storeLargeImage = (state, action) => {
    return { ...state, largeImage: action.data.image };
};

const clearLargeImage = (state, action) => {
    return { ...state, largeImage: null };
};

const startEditExpense = (state, action) => {
    return { ...state, editExpense: action.id };
};

const stopEditExpense = (state, action) => {
    return { ...state, editExpense: null };
};

const storeSuperiors = (state, action) => {
    return { ...state, superiors: [...action.data] };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.START_LOADING:
            return startLoading(state, action);
        case actionTypes.STOP_LOADING:
            return stopLoading(state, action);
        case actionTypes.SUCCESSFULLY_SENT:
            return successfullySent(state, action);
        case actionTypes.STORE_USER:
            return storeUser(state, action);
        case actionTypes.SET_ERROR:
            return setError(state, action);
        case actionTypes.CLEAR_ERROR:
            return clearError(state, action);
        case actionTypes.STORE_FORMS:
            return storeForms(state, action);
        case actionTypes.STORE_FORM:
            return storeForm(state, action);
        case actionTypes.SET_SUCCESS:
            return setSuccess(state, action);
        case actionTypes.CLEAR_SUCCESS:
            return clearSuccess(state, action);
        case actionTypes.SET_REDIRECT:
            return setRedirect(state, action);
        case actionTypes.CLEAR_REDIRECT:
            return clearRedirect(state, action);
        case actionTypes.STORE_USER_ID:
            return storeUserId(state, action);
        case actionTypes.STORE_VEHICLES:
            return storeVehicles(state, action);
        case actionTypes.STORE_PERSONS:
            return storePersons(state, action);
        case actionTypes.STORE_COMPANIES:
            return storeCompanies(state, action);
        case actionTypes.STORE_VEHICLE:
            return storeVehicle(state, action);
        case actionTypes.STORE_NOTIFICATION_USERS:
            return storeNotificationUsers(state, action);
        case actionTypes.STORE_IMAGE:
            return storeImage(state, action);

        case actionTypes.STORE_EXPENSES:
            return storeExpenses(state, action);
        case actionTypes.STORE_LARGE_IMAGE:
            return storeLargeImage(state, action);
        case actionTypes.CLEAR_LARGE_IMAGE:
            return clearLargeImage(state, action);
        case actionTypes.START_EDIT_EXPENSE:
            return startEditExpense(state, action);
        case actionTypes.STOP_EDIT_EXPENSE:
            return stopEditExpense(state, action);
        case actionTypes.STORE_SUPERIORS:
            return storeSuperiors(state, action);

        default:
            return state;
    }
};

export default reducer;
