import React, { Suspense, lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import AdminLayout from "../Layout/AdminLayout";
import * as routes from "./../../shared/routes";

const Logout = lazy(() => import("./../../containers/Logout/Logout"));
const Login = lazy(() => import("./../../containers/Login/Login"));

const LoginRoutes = () => {
    return (
        <AdminLayout>
            <Suspense fallback={<p>Laden...</p>}>
                <Switch>
                    <Route path={routes.LOGOUT} exact component={Logout} />
                    <Route path={routes.LOGIN} exact component={Login} />
                    <Route path={routes.LOGIN + "/:id"} exact component={Login} />
                    <Redirect to={routes.LOGIN} />
                </Switch>
            </Suspense>
        </AdminLayout>
    );
};

export default LoginRoutes;
