import * as actionTypes from "../actions/actionTypes";

const initialState = {
    token: null,
    refreshToken: null,
    autoSignupTried: false,
    user: {
        privilegs: null,
        expenses: null,
        companyIds: []
    },
    forms: null,
    users: null,
    groups: [],
    formCategories: null,
    formData: null,
    form: null,
    superior: {
        expenses: null
    },
    accountingExpenses: null
};

const updateTokens = (state, action) => {
    return { ...state, token: action.token, refreshToken: action.refreshToken, autoSignupTried: true };
};

const setUserData = (state, action) => {
    return { ...state, user: { ...action.data } };
};

const storeUsers = (state, action) => {
    return { ...state, users: [...action.users] };
};

const storeForms = (state, action) => {
    return { ...state, forms: [...action.forms] };
};

const storeForm = (state, action) => {
    return { ...state, form: { ...action.form } };
};

const updateUser = (state, action) => {
    return {
        ...state,
        users: state.users.map((entry) => {
            if (entry.id === action.user.id) {
                return action.user;
            } else {
                return entry;
            }
        })
    };
};

const storeGroups = (state, action) => {
    return { ...state, groups: [...action.groups] };
};

const addFormGroup = (state, action) => {
    return {
        ...state,
        forms: state.forms.map((form) => {
            if (form.id === action.id) {
                return {
                    ...form,
                    groups: [
                        ...form.groups,
                        {
                            id: action.groupId,
                            beekeeperGroupId: action.beekeeperGroupId,
                            users: []
                        }
                    ]
                };
            } else {
                return form;
            }
        })
    };
};

const removeFormGroup = (state, action) => {
    return {
        ...state,
        forms: state.forms.map((form) => {
            if (form.id === action.id) {
                return {
                    ...form,
                    groups: form.groups.filter((entry) => entry.id !== action.groupId)
                };
            } else {
                return form;
            }
        })
    };
};

const addUserToFormGroup = (state, action) => {
    return {
        ...state,
        forms: state.forms.map((form) => {
            if (form.id === action.id) {
                return {
                    ...form,
                    groups: form.groups.map((group) => {
                        if (group.id === action.groupId) {
                            return { ...group, users: [...group.users, action.userId] };
                        } else {
                            return group;
                        }
                    })
                };
            } else {
                return form;
            }
        })
    };
};

const removeUserFromFormGroup = (state, action) => {
    return {
        ...state,
        forms: state.forms.map((form) => {
            if (form.id === action.id) {
                return {
                    ...form,
                    groups: form.groups.map((group) => {
                        if (group.id === action.groupId) {
                            return { ...group, users: group.users.filter((userId) => userId !== action.userId) };
                        } else {
                            return group;
                        }
                    })
                };
            } else {
                return form;
            }
        })
    };
};

const addNotificationUserToForm = (state, action) => {
    return {
        ...state,
        forms: state.forms.map((form) => {
            if (form.id === action.formId) {
                form.notifications.push({ id: action.id, gbKey: action.gbKey, userId: action.userId });
            }
            return form;
        })
    };
};

const removeNotificationUserFromForm = (state, action) => {
    return {
        ...state,
        forms: state.forms.map((form) => {
            if (form.id === action.formId) {
                form.notifications = form.notifications.filter((entry) => !(entry.gbKey === action.gbKey && entry.userId === action.userId));
            }
            return form;
        })
    };
};

const storeFormCategories = (state, action) => {
    return { ...state, formCategories: [...action.formCategories] };
};

const storeFormData = (state, action) => {
    return { ...state, formData: [...action.formData] };
};

const clearFormData = (state, action) => {
    return { ...state, formData: null };
};

const updateFormData = (state, action) => {
    const formCategories = action.data.formChecked
        ? state.formCategories.map((category) => {
              if (category.url === action.url) {
                  return { ...category, countPending: category.countPending - 1 };
              } else {
                  return category;
              }
          })
        : [...state.formCategories];
    return {
        ...state,
        formCategories,
        formData: state.formData.map((form) => {
            if (form.id === action.id) {
                return { ...form, pending: !action.data.formChecked, priority: action.data.priority };
            } else {
                return form;
            }
        })
    };
};

const authLogout = (state, action) => {
    return { ...initialState, autoSignupTried: true };
};

const storeSuperiorExpenses = (state, action) => {
    return { ...state, superior: { ...state.superior, expenses: [...action.data] } };
};

const storeAccountingExpenses = (state, action) => {
    return { ...state, accountingExpenses: [...action.data] };
};

const updateAccountingExpense = (state, action) => {
    const updatedExpenses = state.accountingExpenses.map((entry) => {
        if (entry.id === action.id) {
            return { ...entry, ...action.data };
        }
        return entry;
    });
    return { ...state, accountingExpenses: [...updatedExpenses] };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADMIN_AUTH_UPDATE_TOKENS:
            return updateTokens(state, action);
        case actionTypes.ADMIN_SET_USERDATA:
            return setUserData(state, action);
        case actionTypes.ADMIN_STORE_USERS:
            return storeUsers(state, action);
        case actionTypes.ADMIN_STORE_FORMS:
            return storeForms(state, action);
        case actionTypes.ADMIN_STORE_FORM:
            return storeForm(state, action);
        case actionTypes.ADMIN_UPDATE_USER:
            return updateUser(state, action);
        case actionTypes.ADMIN_STORE_GROUPS:
            return storeGroups(state, action);
        case actionTypes.ADMIN_ADD_FORM_GROUP:
            return addFormGroup(state, action);
        case actionTypes.ADMIN_REMOVE_FORM_GROUP:
            return removeFormGroup(state, action);
        case actionTypes.ADMIN_ADD_USER_TO_FORM_GROUP:
            return addUserToFormGroup(state, action);
        case actionTypes.ADMIN_REMOVE_USER_FROM_FORM_GROUP:
            return removeUserFromFormGroup(state, action);
        case actionTypes.ADMIN_ADD_NOTIFICATION_USER_TO_FORM:
            return addNotificationUserToForm(state, action);
        case actionTypes.ADMIN_REMOVE_NOTIFICATION_USER_FROM_FORM:
            return removeNotificationUserFromForm(state, action);
        case actionTypes.ADMIN_STORE_FORM_CATEGORIES:
            return storeFormCategories(state, action);
        case actionTypes.ADMIN_STORE_FORM_DATA:
            return storeFormData(state, action);
        case actionTypes.ADMIN_CLEAR_FORM_DATA:
            return clearFormData(state, action);
        case actionTypes.ADMIN_UPDATE_FORM_DATA:
            return updateFormData(state, action);
        case actionTypes.ADMIN_AUTH_LOGOUT:
            return authLogout(state, action);
        case actionTypes.ADMIN_STORE_SUPERIOR_EXPENSES:
            return storeSuperiorExpenses(state, action);
        case actionTypes.ADMIN_STORE_ACCOUNTING_EXPENSES:
            return storeAccountingExpenses(state, action);
        case actionTypes.ADMIN_UPDATE_ACCOUNTING_EXPENSE:
            return updateAccountingExpense(state, action);

        default:
            return state;
    }
};

export default reducer;
