import * as actionTypes from "./actionTypes";
import axios from "../../axios";
import * as routes from "../../shared/routes";

export const startLoading = () => {
    return {
        type: actionTypes.START_LOADING
    };
};

export const stopLoading = () => {
    return {
        type: actionTypes.STOP_LOADING
    };
};

export const setError = (error) => {
    return {
        type: actionTypes.SET_ERROR,
        error: error
    };
};

export const _clearError = () => {
    return {
        type: actionTypes.CLEAR_ERROR
    };
};

export const _clearRedirect = () => {
    return {
        type: actionTypes.CLEAR_REDIRECT
    };
};

export const setRedirect = (url) => {
    return {
        type: actionTypes.SET_REDIRECT,
        url: url
    };
};

const successfullySent = () => {
    return {
        type: actionTypes.SUCCESSFULLY_SENT
    };
};

export const _sendData = (data, url) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .post("form" + url, data)
            .then((response) => {
                dispatch(stopLoading());
                dispatch(setRedirect(routes.FORMS_FINISH));
                dispatch(successfullySent());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

export const _updateFormData = (url, id, data) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .post("form" + url + "/" + id, data)
            .then((response) => {
                dispatch(stopLoading());
                dispatch(setRedirect(routes.FORMS_FINISH));
                dispatch(successfullySent());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const storeVehicles = (vehicles) => {
    return {
        type: actionTypes.STORE_VEHICLES,
        vehicles
    };
};

export const _getVehicles = () => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get("vehicles")
            .then((response) => {
                dispatch(storeVehicles(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const storePersons = (persons) => {
    return {
        type: actionTypes.STORE_PERSONS,
        persons
    };
};

export const _getPersons = () => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get("persons")
            .then((response) => {
                dispatch(storePersons(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const storeCompanies = (companies) => {
    return {
        type: actionTypes.STORE_COMPANIES,
        companies
    };
};

export const _getCompanies = () => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get("companies")
            .then((response) => {
                dispatch(storeCompanies(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

export const storeUserId = (userId) => {
    return {
        type: actionTypes.STORE_USER_ID,
        userId: userId
    };
};

export const _getUserData = (userId) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get("beekeeper/user/" + userId)
            .then((response) => {
                dispatch(storeUserId(userId));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const storeForms = (forms) => {
    return {
        type: actionTypes.STORE_FORMS,
        forms: forms
    };
};

export const _getForms = () => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get("forms")
            .then((response) => {
                dispatch(storeForms(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

export const _storeVehicle = (vehicle) => {
    return {
        type: actionTypes.STORE_VEHICLE,
        vehicle
    };
};

const storeNotificationUsers = (users) => {
    return {
        type: actionTypes.STORE_NOTIFICATION_USERS,
        users
    };
};

export const _getNotificationUsers = (formUrl, gbKey) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get("notificationUsers" + formUrl + "/" + gbKey)
            .then((response) => {
                dispatch(storeNotificationUsers(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const storeForm = (form) => {
    return {
        type: actionTypes.STORE_FORM,
        form
    };
};

const storeImage = (image, name) => {
    return {
        type: actionTypes.STORE_IMAGE,
        image: image,
        name: name
    };
};

export const _getImage = (id, imageFileName) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get("image/" + id + "/" + imageFileName)
            .then((response) => {
                dispatch(storeImage(response.data.image, imageFileName));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

export const _getFormData = (state, id, userId) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get("form/" + state + "/" + id + "/" + userId)
            .then((response) => {
                dispatch(storeForm(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(stopLoading());
                dispatch(setRedirect(routes.FORMS + routes.ERROR));
                dispatch(setError("Du hast keine Berechtigung dieses Formular aufzurufen."));
            });
    };
};

export const _formReply = (id, data) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .patch("form/" + id + "/replay", data)
            .then((response) => {
                dispatch(stopLoading());
                dispatch(setRedirect(routes.FORMS_FINISH));
                dispatch(successfullySent());
            })
            .catch((err) => {
                dispatch(stopLoading());
                dispatch(setRedirect(routes.FORMS + routes.ERROR));
                dispatch(setError("Du hast keine Berechtigung dieses Formular aufzurufen."));
            });
    };
};

const storeExpenses = (data) => {
    return {
        type: actionTypes.STORE_EXPENSES,
        data
    };
};

export const _getExpenses = (userId) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get("expenses/" + userId)
            .then((response) => {
                dispatch(storeExpenses(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(stopLoading());
                dispatch(setRedirect(routes.FORMS + routes.ERROR));
                dispatch(setError("Du hast keine Berechtigung dieses Formular aufzurufen."));
            });
    };
};

export const _createExpense = (userId, data) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .post("expenses/" + userId, data)
            .then((response) => {
                dispatch(storeExpenses(response.data));
                dispatch(_stopEditExpense());
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(stopLoading());
                dispatch(setRedirect(routes.FORMS + routes.ERROR));
                dispatch(setError("Du hast keine Berechtigung dieses Formular aufzurufen."));
            });
    };
};

export const _updateExpense = (userId, id, data) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .post(`expenses/${userId}/${id}`, data)
            .then((response) => {
                dispatch(storeExpenses(response.data));
                dispatch(_stopEditExpense());
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(stopLoading());
                dispatch(setRedirect(routes.FORMS + routes.ERROR));
                dispatch(setError("Du hast keine Berechtigung dieses Formular aufzurufen."));
            });
    };
};

export const _deleteExpense = (userId, id) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .delete(`expenses/${userId}/${id}`)
            .then((response) => {
                dispatch(storeExpenses(response.data));
                dispatch(_stopEditExpense());
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(stopLoading());
                dispatch(setRedirect(routes.FORMS + routes.ERROR));
                dispatch(setError("Du hast keine Berechtigung dieses Formular aufzurufen."));
            });
    };
};

const storeLargeImage = (data) => {
    return {
        type: actionTypes.STORE_LARGE_IMAGE,
        data
    };
};

export const _loadLargeImage = (url) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get(url)
            .then((response) => {
                dispatch(storeLargeImage(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(stopLoading());
                dispatch(setError("Das Bild wurde nicht gefunden."));
            });
    };
};

export const _clearLargeImage = () => {
    return {
        type: actionTypes.CLEAR_LARGE_IMAGE
    };
};

export const _startEditExpense = (id = 0) => {
    return {
        type: actionTypes.START_EDIT_EXPENSE,
        id
    };
};

export const _stopEditExpense = () => {
    return {
        type: actionTypes.STOP_EDIT_EXPENSE
    };
};

const storeSuperiors = (data) => {
    return {
        type: actionTypes.STORE_SUPERIORS,
        data
    };
};

export const _getSuperiors = (userId) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get(`superiors/${userId}`)
            .then((response) => {
                dispatch(storeSuperiors(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(setError(err.response.statusText));
                dispatch(stopLoading());
            });
    };
};
