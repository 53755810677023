import React, { Suspense, lazy } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import AdminLayout from "../Layout/AdminLayout";
import * as routes from "./../../shared/routes";
import * as userPrivilegs from "./../../shared/userPrivilegs";
import * as expensesFunction from "./../../shared/expensesFunctions";

const FormAdmin = lazy(() => import("./../../containers/FormAdmin/FormAdmin"));
const FormData = lazy(() => import("./../../containers/FormData/FormData"));
const Pikett = lazy(() => import("./../../containers/Pikett/Pikett"));
const TV_Ersatzteile = lazy(() => import("./../../containers/TV_Ersatzteile/TV_Ersatzteile"));
const Neutralisationsanlage = lazy(() => import("./../../containers/Neutralisationsanlage/Neutralisationsanlage"));
const Expenses = lazy(() => import("./../../containers/Expenses/Expenses"));

const Users = lazy(() => import("./../../containers/Users/Users"));
const Logout = lazy(() => import("./../../containers/Logout/Logout"));

const AdminRoutes = () => {
    const location = useLocation();
    const privilegs = useSelector((state) => state.admin.user.privilegs);
    const expenses = useSelector((state) => state.admin.user.expenses);

    let redirect = null;
    if (location.pathname.indexOf(routes.LOGIN) === 0) {
        if (privilegs.includes(userPrivilegs.FORM_DATA)) {
            redirect = <Redirect to={routes.FORM_DATA} />;
        } else if (expenses !== expensesFunction.NO_FUNCTION) {
            redirect = <Redirect to={routes.EXPENSES} />;
        } else if (privilegs.includes(userPrivilegs.FORM_CONFIG)) {
            redirect = <Redirect to={routes.FORM_ADMIN} />;
        } else if (privilegs.includes(userPrivilegs.PIKETT)) {
            redirect = <Redirect to={routes.PIKETT} />;
        }
    }
    return (
        <AdminLayout>
            <Suspense fallback={<p>Laden...</p>}>
                <Switch>
                    <Route path={routes.LOGOUT} exact component={Logout} />
                    {privilegs.includes(userPrivilegs.ADMIN) && <Route path={routes.USERS + "/:id?"} exact component={Users} />}
                    {(privilegs.includes(userPrivilegs.ADMIN) || privilegs.includes(userPrivilegs.PIKETT)) && <Route path={routes.PIKETT} exact component={Pikett} />}
                    {(privilegs.includes(userPrivilegs.ADMIN) || privilegs.includes(userPrivilegs.TV_ERSATZTEILE)) && <Route path={routes.TV_ERSATZTEILE} exact component={TV_Ersatzteile} />}
                    {(privilegs.includes(userPrivilegs.ADMIN) || privilegs.includes(userPrivilegs.FORM_DATA)) && <Route path={routes.FORM_DATA + "/:url?/:id?"} exact component={FormData} />}
                    {(privilegs.includes(userPrivilegs.ADMIN) || privilegs.includes(userPrivilegs.FORM_CONFIG)) && <Route path={routes.FORM_ADMIN + "/:id?"} exact component={FormAdmin} />}
                    {(privilegs.includes(userPrivilegs.ADMIN) || privilegs.includes(userPrivilegs.NEUTRALISATION)) && <Route path={routes.NEUTRALISATIONSANLAGE} exact component={Neutralisationsanlage} />}
                    {expenses !== expensesFunction.NO_FUNCTION && <Route path={routes.EXPENSES} exact component={Expenses} />}
                    {redirect}
                </Switch>
            </Suspense>
        </AdminLayout>
    );
};

export default AdminRoutes;
