import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import * as userPrivilegs from "../../shared/userPrivilegs";
import * as expensesFunction from "../../shared/expensesFunctions";
import * as routes from "../../shared/routes";

import styles from "./Menu.module.css";

const menuItems = {
    forms: (
        <NavLink key={routes.FORM_DATA} to={routes.FORM_DATA} activeClassName={styles.active}>
            Formulare
        </NavLink>
    ),
    formAdmin: (
        <NavLink key={routes.FORM_ADMIN} to={routes.FORM_ADMIN} activeClassName={styles.active}>
            Administration
        </NavLink>
    ),
    pikett: (
        <NavLink key={routes.PIKETT} to={routes.PIKETT} activeClassName={styles.active}>
            Pikett
        </NavLink>
    ),
    tv: (
        <NavLink key={routes.TV_ERSATZTEILE} to={routes.TV_ERSATZTEILE} activeClassName={styles.active}>
            TV Ersatzteile
        </NavLink>
    ),
    neutralisation: (
        <NavLink key={routes.NEUTRALISATIONSANLAGE} to={routes.NEUTRALISATIONSANLAGE} activeClassName={styles.active}>
            Neutralisationsanlage
        </NavLink>
    ),
    expenses: (
        <NavLink key={routes.EXPENSES} to={routes.EXPENSES} activeClassName={styles.active}>
            Spesen
        </NavLink>
    ),
    users: (
        <NavLink key={routes.USERS} to={routes.USERS} activeClassName={styles.active}>
            Benutzer
        </NavLink>
    )
};

const Menu = ({ authenticated, privilegs, expenses }) => {
    const menus = [];
    const { pathname } = useLocation();
    if (authenticated) {
        if (privilegs.includes(userPrivilegs.ADMIN)) {
            menus.push(
                <div className={styles.pulldown} key="forms">
                    <span>Formulare</span>
                    <ul>
                        <li>{menuItems.forms}</li>
                        <li>{menuItems.formAdmin}</li>
                    </ul>
                </div>
            );
            menus.push(
                <div className={styles.pulldown} key="additions">
                    <span>Diverses</span>
                    <ul>
                        <li>{menuItems.pikett}</li>
                        <li>{menuItems.tv}</li>
                        <li>{menuItems.neutralisation}</li>
                    </ul>
                </div>
            );
            menus.push(menuItems.users);
        } else {
            if (privilegs.includes(userPrivilegs.FORM_DATA)) {
                menus.push(menuItems.forms);
            }
            if (privilegs.includes(userPrivilegs.FORM_CONFIG)) {
                menus.push(menuItems.formAdmin);
            }
            if (privilegs.includes(userPrivilegs.PIKETT)) {
                menus.push(menuItems.pikett);
            }
            if (privilegs.includes(userPrivilegs.TV_ERSATZTEILE)) {
                menus.push(menuItems.tv);
            }
            if (privilegs.includes(userPrivilegs.NEUTRALISATION)) {
                menus.push(menuItems.neutralisation);
            }
        }
        if (expenses !== expensesFunction.NO_FUNCTION) {
            menus.push(menuItems.expenses);
        }
    } else if (pathname !== routes.LOGOUT) {
        menus.push(<span key="login">Login</span>);
    }
    return <nav className={styles.nav}>{menus}</nav>;
};

export default Menu;
