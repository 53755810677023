import React, { Suspense, lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Layout from "../Layout/Layout";
import * as routes from "./../../shared/routes";

const FormDone = lazy(() => import("./../../containers/FormDone/FormDone"));
const Aktennotiz = lazy(() => import("./../../containers/Forms/Aktennotiz/Aktennotiz"));
const Arztzeugnis = lazy(() => import("./../../containers/Forms/Arztzeugnis/Arztzeugnis"));
const FahrzeugWaehlen = lazy(() => import("./../../containers/Forms/FahrzeugWaehlen/FahrzeugWaehlen"));
const Fahrzeugreparatur = lazy(() => import("./../../containers/Forms/Fahrzeugreparatur/Fahrzeugreparatur"));
const FahrzeugUnfall = lazy(() => import("./../../containers/Forms/FahrzeugUnfall/FahrzeugUnfall"));
const Ferienantrag = lazy(() => import("./../../containers/Forms/Ferienantrag/Ferienantrag"));
const FotosTechniker = lazy(() => import("./../../containers/Forms/FotosTechniker/FotosTechniker"));
const GespraechMitPersonalabteilung = lazy(() => import("./../../containers/Forms/GespraechMitPersonalabteilung/GespraechMitPersonalabteilung"));
const Haftungsausschluss = lazy(() => import("./../../containers/Forms/Haftungsausschluss/Haftungsausschluss"));
const Ideenbox = lazy(() => import("./../../containers/Forms/Ideenbox/Ideenbox"));
const KleiderBestellung = lazy(() => import("./../../containers/Forms/KleiderBestellung/KleiderBestellung"));
const LSVA_karten_auslesen = lazy(() => import("./../../containers/Forms/LSVA_karten_auslesen/LSVA_karten_auslesen"));
const PersonenUnfall = lazy(() => import("./../../containers/Forms/PersonenUnfall/PersonenUnfall"));
const SchaechteAbsaugen = lazy(() => import("./../../containers/Forms/SchaechteAbsaugen/SchaechteAbsaugen"));
const TicketWalterZoo = lazy(() => import("./../../containers/Forms/TicketWalterZoo/TicketWalterZoo"));
const ZueriZooTickets = lazy(() => import("./../../containers/Forms/ZueriZooTickets/ZueriZooTickets"));
const WartungsarbeitenKSG = lazy(() => import("./../../containers/Forms/WartungsarbeitenKSG/WartungsarbeitenKSG"));
const WartungsarbeitenKSG_TV = lazy(() => import("./../../containers/Forms/WartungsarbeitenKSG_TV/WartungsarbeitenKSG_TV"));
const WartungsarbeitenLKW = lazy(() => import("./../../containers/Forms/WartungsarbeitenLKW/WartungsarbeitenLKW"));
const WartungsarbeitenWischer = lazy(() => import("./../../containers/Forms/WartungsarbeitenWischer/WartungsarbeitenWischer"));
const Fahrzeugausleihe = lazy(() => import("./../../containers/Forms/Fahrzeugausleihe/Fahrzeugausleihe"));
const FahrzeugausleiheKontrolle = lazy(() => import("./../../containers/Forms/Fahrzeugausleihe/FahrzeugausleiheKontrolle"));
const ErrorPage = lazy(() => import("./../../containers/Forms/ErrorPage/ErrorPage"));
const Fahrzeugrueckgabe = lazy(() => import("./../../containers/Forms/Fahrzeugausleihe/Fahrzeugrueckgabe"));
const PoolfahrzeugMieten = lazy(() => import("./../../containers/Forms/PoolfahrzeugMieten/PoolfahrzeugMieten"));
const PoolfahrzeugMietenKontrolle = lazy(() => import("./../../containers/Forms/PoolfahrzeugMieten/PoolfahrzeugMietenKontrolle"));
const PoolfahrzeugRueckgabe = lazy(() => import("./../../containers/Forms/PoolfahrzeugMieten/PoolfahrzeugRueckgabe"));
const SauganhaengerAusleihe = lazy(() => import("./../../containers/Forms/SauganhaengerAusleihe/SauganhaengerAusleihe"));
const SauganhaengerAusleiheKontrolle = lazy(() => import("./../../containers/Forms/SauganhaengerAusleihe/SauganhaengerAusleiheKontrolle"));
const SauganhaengerRueckgabe = lazy(() => import("./../../containers/Forms/SauganhaengerAusleihe/SauganhaengerRueckgabe"));
const ExpensesOverview = lazy(() => import("./../../containers/Forms/ExpensesOverview/ExpensesOverview"));
const Login = lazy(() => import("./../../containers/Login/Login"));

const UserRoutes = () => {
    return (
        <Layout>
            <Suspense fallback={<p>Laden...</p>}>
                <Switch>
                    <Route path={routes.FORMS + routes.FAHRZEUG + "/:checkUserId"} exact component={FahrzeugWaehlen} />
                    <Route path={routes.FORMS + routes.FAHRZEUG_REPARATUR} exact component={Fahrzeugreparatur} />
                    <Route path={routes.FORMS + routes.FAHRZEUG_UNFALL} exact component={FahrzeugUnfall} />
                    <Route path={routes.FORMS + routes.LSVA_KARTEN_AUSLESEN} exact component={LSVA_karten_auslesen} />
                    <Route path={routes.FORMS + routes.WARTUNGSARBEITEN_KSG} exact component={WartungsarbeitenKSG} />
                    <Route path={routes.FORMS + routes.WARTUNGSARBEITEN_KSG_TV} exact component={WartungsarbeitenKSG_TV} />
                    <Route path={routes.FORMS + routes.WARTUNGSARBEITEN_LKW} exact component={WartungsarbeitenLKW} />
                    <Route path={routes.FORMS + routes.WARTUNGSARBEITEN_WISCHER} exact component={WartungsarbeitenWischer} />
                    <Route path={routes.FORMS + routes.FAHRZEUGAUSLEIHE + "/:checkUserId?/:id?"} exact component={Fahrzeugausleihe} />
                    <Route path={routes.FORMS + routes.FAHRZEUGAUSLEIHE + routes.CHECK + "/:userId/:id"} exact component={FahrzeugausleiheKontrolle} />
                    <Route path={routes.FORMS + routes.FAHRZEUGRUECKGABE + "/:checkUserId?/:id?"} exact component={Fahrzeugrueckgabe} />
                    <Route path={routes.FORMS + routes.FAHRZEUGRUECKGABE + routes.CHECK + "/:userId/:id"} exact component={FahrzeugausleiheKontrolle} />

                    <Route path={routes.FORMS + routes.POOLFAHRZEUG_AUSLEIHE + "/:checkUserId?/:id?"} exact component={PoolfahrzeugMieten} />
                    <Route path={routes.FORMS + routes.POOLFAHRZEUG_AUSLEIHE + routes.CHECK + "/:userId/:id"} exact component={PoolfahrzeugMietenKontrolle} />
                    <Route path={routes.FORMS + routes.POOLFAHRZEUG_RUECKGABE + "/:checkUserId?/:id?"} exact component={PoolfahrzeugRueckgabe} />
                    <Route path={routes.FORMS + routes.POOLFAHRZEUG_RUECKGABE + routes.CHECK + "/:userId/:id"} exact component={PoolfahrzeugMietenKontrolle} />

                    <Route path={routes.FORMS + routes.SAUGANHAENGER_AUSLEIHE + "/:checkUserId?/:id?"} exact component={SauganhaengerAusleihe} />
                    <Route path={routes.FORMS + routes.SAUGANHAENGER_AUSLEIHE + routes.CHECK + "/:userId/:id"} exact component={SauganhaengerAusleiheKontrolle} />
                    <Route path={routes.FORMS + routes.SAUGANHAENGER_RUECKGABE + "/:checkUserId?/:id?"} exact component={SauganhaengerRueckgabe} />
                    <Route path={routes.FORMS + routes.SAUGANHAENGER_RUECKGABE + routes.CHECK + "/:userId/:id"} exact component={SauganhaengerAusleiheKontrolle} />

                    <Route path={routes.FORMS + routes.AKTENNOTIZ + "/:checkUserId"} exact component={Aktennotiz} />
                    <Route path={routes.FORMS + routes.ARZTZEUGNIS + "/:checkUserId"} exact component={Arztzeugnis} />
                    <Route path={routes.FORMS + routes.FERIENANTRAG + "/:checkUserId"} exact component={Ferienantrag} />
                    <Route path={routes.FORMS + routes.FOTOS_TECHNIKER + "/:checkUserId"} exact component={FotosTechniker} />
                    <Route path={routes.FORMS + routes.GESPRAECH_MIT_PERSONALABTEILUNG + "/:checkUserId"} exact component={GespraechMitPersonalabteilung} />
                    <Route path={routes.FORMS + routes.HAFTUNGSAUSSCHLUSS + "/:checkUserId"} exact component={Haftungsausschluss} />
                    <Route path={routes.FORMS + routes.IDEENBOX + "/:checkUserId"} exact component={Ideenbox} />
                    <Route path={routes.FORMS + routes.KLEIDERBESTELLUNG + "/:checkUserId"} exact component={KleiderBestellung} />
                    <Route path={routes.FORMS + routes.PERSONEN_UNFALL + "/:checkUserId"} exact component={PersonenUnfall} />
                    <Route path={routes.FORMS + routes.SCHAECHTE_ABSAUGEN + "/:checkUserId"} exact component={SchaechteAbsaugen} />
                    <Route path={routes.FORMS + routes.TICKET_WALTER_ZOO + "/:checkUserId"} exact component={TicketWalterZoo} />
                    <Route path={routes.FORMS + routes.ZUERI_ZOO_TICKETS + "/:checkUserId"} exact component={ZueriZooTickets} />

                    <Route path={routes.FORMS + routes.EXPENSES + "/:checkUserId"} exact component={ExpensesOverview} />

                    {/* <Route path={routes.FORMS + "/:form/:checkUserId"} exact component={Forms} /> */}
                    <Route path={routes.FORMS_FINISH} component={FormDone} />
                    <Route path={routes.FORMS + routes.ERROR} exact component={ErrorPage} />
                    <Route path={routes.LOGIN} exact component={Login} />
                    <Redirect to={routes.LOGIN} />
                </Switch>
            </Suspense>
        </Layout>
    );
};

export default UserRoutes;
