import React, { useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { _getCompanies, _getPersons, _clearRedirect } from "./store/actions/actions";
import { _tryAutoSignup } from "./store/actions/adminActions";

import * as routes from "./shared/routes";

import UserRoutes from "./hoc/UserRoutes/UserRoutes";
import LoginRoutes from "./hoc/LoginRoutes/LoginRoutes";
import AdminRoutes from "./hoc/AdminRoutes/AdminRoutes";

const App = () => {
    const authenticated = useSelector((state) => state.admin.token !== null);
    const autoSignupTried = useSelector((state) => state.admin.autoSignupTried);
    const redirect = useSelector((state) => state.main.redirect);
    const companies = useSelector((state) => state.main.companies);
    const persons = useSelector((state) => state.main.persons);
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const tryAutoSignup = () => dispatch(_tryAutoSignup());
    const clearRedirect = useCallback(() => dispatch(_clearRedirect()), [dispatch]);
    const getCompanies = useCallback(() => dispatch(_getCompanies()), [dispatch]);
    const getPersons = useCallback(() => dispatch(_getPersons()), [dispatch]);

    useEffect(() => {
        getCompanies();
        getPersons();
    }, [getCompanies, getPersons]);

    useEffect(() => {
        if (redirect) {
            history.push(redirect);
            clearRedirect();
        }
    }, [redirect, history, clearRedirect]);

    if (companies.length === 0 || persons.length === 0) {
        return null;
    }

    if (location.pathname.indexOf(routes.FORMS + "/") === 0) {
        return <UserRoutes />;
    } else {
        if (!authenticated) {
            if (autoSignupTried) {
                return <LoginRoutes />;
            } else {
                tryAutoSignup();
            }
        } else {
            return <AdminRoutes />;
        }
    }
    return null;
};

export default App;
