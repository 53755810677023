import * as actionTypes from "./actionTypes";
import * as routes from "../../shared/routes";

import axios from "../../axios";
import { setError, setRedirect, startLoading, stopLoading, storeUserId } from "./actions";

export const setSuccess = (success) => {
    return {
        type: actionTypes.SET_SUCCESS,
        success: success
    };
};

export const _clearSuccess = () => {
    return {
        type: actionTypes.CLEAR_SUCCESS
    };
};

const updateTokens = (token, refreshToken) => {
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    return {
        type: actionTypes.ADMIN_AUTH_UPDATE_TOKENS,
        token: token,
        refreshToken: refreshToken
    };
};

export const _checkOrSendLoginCode = (userId) => {
    return (dispatch) => {
        dispatch(startLoading());

        axios
            .post("admin/loginCode", { userId })
            .then((response) => {
                dispatch(stopLoading());
                if (response.status === 201) {
                    dispatch(setSuccess("Du hast eine Beekeeper Nachricht mit einem PIN-Code erhalten. Bitte melde Dich mit diesem PIN-Code hier an:"));
                } else if (response.status === 204) {
                    dispatch(setSuccess("Dein PIN-Code welchen Du als Beekeeper Nachricht erhalten hast ist immer noch gültig."));
                }
            })
            .catch((err) => {
                dispatch(setError("Ungültiger Aufruf!"));
                dispatch(stopLoading());
            });
    };
};

export const _tryAutoSignup = () => {
    return (dispatch) => {
        const token = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refreshToken");
        if (!token) {
            dispatch(_logout());
        } else {
            axios
                .get("admin/autoSignup")
                .then((response) => {
                    dispatch(setUserData(response.data));
                    dispatch(storeUserId(response.data.userId));
                    if (response.config.updatedToken) {
                        const { token, refreshToken } = response.config.updatedToken;
                        dispatch(updateTokens(token, refreshToken));
                    } else {
                        dispatch(updateTokens(token, refreshToken));
                    }
                    dispatch(stopLoading());
                })
                .catch((err) => {
                    dispatch(stopLoading());
                    dispatch(_logout());
                });
        }
    };
};

export const _logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("expTime");
    localStorage.removeItem("refreshToken");
    return {
        type: actionTypes.ADMIN_AUTH_LOGOUT
    };
};

const setUserData = (data) => {
    return {
        type: actionTypes.ADMIN_SET_USERDATA,
        data
    };
};

export const _pinLogin = (userId, pinCode) => {
    return (dispatch) => {
        dispatch(startLoading());

        axios
            .post("admin/login", { userId, pinCode })
            .then((response) => {
                if (response.status === 201) {
                    dispatch(setSuccess("Dein PIN-Code war abgelaufen. Du hast eine Beekeeper Nachricht mit einem neuen PIN-Code erhalten."));
                } else {
                    dispatch(setUserData(response.data.user));
                    dispatch(updateTokens(response.data.auth.token, response.data.auth.refreshToken));
                    dispatch(storeUserId(userId));
                }
                dispatch(stopLoading());
            })
            .catch((err) => {
                switch (err.response.status) {
                    case 403:
                        dispatch(setError("Der PIN-Code ist falsch."));
                        break;

                    case 404:
                        dispatch(setError("Der Benutzer wurde nicht gefunden."));
                        break;

                    default:
                }
                dispatch(stopLoading());
            });
    };
};

const storeForms = (forms) => {
    return {
        type: actionTypes.ADMIN_STORE_FORMS,
        forms: forms
    };
};

export const _getForms = () => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get("admin/form")
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(storeForms(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const storeGroups = (groups) => {
    return {
        type: actionTypes.ADMIN_STORE_GROUPS,
        groups: groups
    };
};

export const _getGroups = () => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get("admin/beekeeper/groups")
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(storeGroups(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

export const _savePikettPDF = (data) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .post("admin/pikett", data)
            .then((response) => {
                dispatch(setSuccess("Das Dokument wurde erfolgreich an Beekeeper gesendet!"));
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

export const _saveTvSparePartsPDF = (data) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .post("admin/tvSpareParts", data)
            .then((response) => {
                dispatch(setSuccess("Das Dokument wurde erfolgreich an Beekeeper gesendet!"));
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const storeFormCategories = (formCategories) => {
    return {
        type: actionTypes.ADMIN_STORE_FORM_CATEGORIES,
        formCategories: formCategories
    };
};

export const _getFormCategories = () => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get("admin/formdata/categories")
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(storeFormCategories(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const storeUsers = (users) => {
    return {
        type: actionTypes.ADMIN_STORE_USERS,
        users: users
    };
};

export const _getUsers = () => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get("admin/user")
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(storeUsers(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

export const _updateUser = (user) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .patch("admin/user/" + user.id, user)
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(updateUser(user));
                dispatch(setRedirect(routes.USERS));
                dispatch(setSuccess(user.firstname + " " + user.lastname + " wurde erfolgreich gespeichert."));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const updateUser = (user) => {
    return {
        type: actionTypes.ADMIN_UPDATE_USER,
        user: user
    };
};

const storeFormData = (formData) => {
    return {
        type: actionTypes.ADMIN_STORE_FORM_DATA,
        formData: formData
    };
};

export const _getFormsOfType = (url) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get("admin/formdata/forms/" + url)
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(storeFormData(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

export const _clearFormData = () => {
    return {
        type: actionTypes.ADMIN_CLEAR_FORM_DATA
    };
};

const storeForm = (form) => {
    return {
        type: actionTypes.ADMIN_STORE_FORM,
        form: form
    };
};

export const _getForm = (url, id) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get("admin/formdata/forms/" + url + "/" + id)
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(storeForm(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const updateFormData = (url, id, data) => {
    return {
        type: actionTypes.ADMIN_UPDATE_FORM_DATA,
        url,
        id,
        data
    };
};

export const _saveFormResponse = (url, id, data) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .patch("admin/formdata/forms/" + url + "/" + id, data)
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(updateFormData(url, id, data));
                dispatch(_getForm(url, id));
                dispatch(setSuccess("Das Formular wurde erfolgreich gespeichert."));
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

export const _createNeutralisationsanlagePDF = (data) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .post("admin/neutralisationsanlage", data)
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(setSuccess("Das PDF wurde generiert."));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const addFormGroup = (id, groupId, beekeeperGroupId) => {
    return {
        type: actionTypes.ADMIN_ADD_FORM_GROUP,
        id,
        groupId,
        beekeeperGroupId
    };
};

export const _addFormGroup = (id, beekeeperGroupId) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .post("admin/form/" + id + "/group", { beekeeperGroupId })
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(addFormGroup(id, response.data.groupId, beekeeperGroupId));
                dispatch(setSuccess("Das Gruppe wurde efolgreich hinzugefügt."));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const removeFormGroup = (id, groupId) => {
    return {
        type: actionTypes.ADMIN_REMOVE_FORM_GROUP,
        id: id,
        groupId: groupId
    };
};

export const _removeFormGroup = (id, groupId) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .delete("admin/form/" + id + "/group/" + groupId)
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(removeFormGroup(id, groupId));
                dispatch(setSuccess("Das Gruppe wurde efolgreich entfernt."));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const addUserToFormGroup = (id, groupId, userId) => {
    return {
        type: actionTypes.ADMIN_ADD_USER_TO_FORM_GROUP,
        id,
        groupId,
        userId
    };
};

export const _addUserToFormGroup = (id, groupId, userId) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .post("admin/form/" + id + "/group/" + groupId, { userId })
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(addUserToFormGroup(id, groupId, userId));
                dispatch(setSuccess("Der Benutzer wurde erfolgreich der Gruppe hinzugefügt."));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const removeUserFromFormGroup = (id, groupId, userId) => {
    return {
        type: actionTypes.ADMIN_REMOVE_USER_FROM_FORM_GROUP,
        id,
        groupId,
        userId
    };
};

export const _removeUserFromFormGroup = (id, groupId, userId) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .delete("admin/form/" + id + "/group/" + groupId + "/" + userId)
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(removeUserFromFormGroup(id, groupId, userId));
                dispatch(setSuccess("Der Benutzer wurde erfolgreich aus der Gruppe entfernt."));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const addNotificationUserToForm = (id, formId, gbKey, userId) => {
    return {
        type: actionTypes.ADMIN_ADD_NOTIFICATION_USER_TO_FORM,
        id,
        formId,
        gbKey,
        userId
    };
};

export const _addNotificationUserToForm = (formId, gbKey, userId) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .post("admin/form/" + formId + "/notification", { gbKey, userId })
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                const { id, formId, gbKey, userId } = response.data;
                dispatch(addNotificationUserToForm(id, formId, gbKey, userId));
                dispatch(setSuccess("Der Sachverständige wurde erfolgreich hinzugefügt."));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const removeNotificationUserFromForm = (formId, gbKey, userId) => {
    return {
        type: actionTypes.ADMIN_REMOVE_NOTIFICATION_USER_FROM_FORM,
        formId,
        gbKey,
        userId
    };
};

export const _removeNotificationUserFromForm = (formId, gbKey, userId) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .delete("admin/form/" + formId + "/notification/" + gbKey + "/" + userId)
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(removeNotificationUserFromForm(formId, gbKey, userId));
                dispatch(setSuccess("Der Sachverständige wurde erfolgreich entfernt."));
                dispatch(stopLoading());
            })
            .catch((err) => {
                //dispatch(setError(err.response.data.message));
                dispatch(stopLoading());
            });
    };
};

const storeSuperiorExpenses = (data) => {
    return {
        type: actionTypes.ADMIN_STORE_SUPERIOR_EXPENSES,
        data
    };
};

export const _superiorGetExpensesToCheck = () => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get(`admin/expenses/superior`)
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(storeSuperiorExpenses(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(setError(err.response.statusText));
                dispatch(stopLoading());
            });
    };
};

export const _superiorExpenseOk = (expenseId) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .patch(`admin/expenses/superior/${expenseId}/ok`)
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(storeSuperiorExpenses(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(setError(err.response.statusText));
                dispatch(stopLoading());
            });
    };
};

export const _superiorExpenseFail = (expenseId, data) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .patch(`admin/expenses/superior/${expenseId}/fail`, data)
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(storeSuperiorExpenses(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(setError(err.response.statusText));
                dispatch(stopLoading());
            });
    };
};

const storeAccountingExpenses = (data) => {
    return {
        type: actionTypes.ADMIN_STORE_ACCOUNTING_EXPENSES,
        data
    };
};

export const _accountingGetExpensesToPay = () => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .get(`admin/expenses/accounting`)
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(storeAccountingExpenses(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(setError(err.response.statusText));
                dispatch(stopLoading());
            });
    };
};

const updateAccountingExpense = (id, data) => {
    return {
        type: actionTypes.ADMIN_UPDATE_ACCOUNTING_EXPENSE,
        id,
        data
    };
};

export const _accountingSaveExpense = (id, data) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .patch(`admin/expenses/accounting/${id}`, data)
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(updateAccountingExpense(id, response.data));
                dispatch(setSuccess("Der Speseneintrag wurde erfolgreich gespeichert"));
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(setError(err.response.statusText));
                dispatch(stopLoading());
            });
    };
};

export const _accountingCompleteExpenses = (userId, expenseIds, username) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .patch(`admin/expenses/accounting/complete/${userId}`, { expenseIds })
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(storeAccountingExpenses(response.data));
                dispatch(setSuccess(`Die Spesen von ${username} wurden erfolgreich abgeschlossen`));
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(setError(err.response.statusText));
                dispatch(stopLoading());
            });
    };
};

export const _accountingDeny = (id, data) => {
    return (dispatch) => {
        dispatch(startLoading());
        axios
            .patch(`admin/expenses/accounting/${id}/deny`, data)
            .then((response) => {
                if (response.config.updatedToken) {
                    const { token, refreshToken } = response.config.updatedToken;
                    dispatch(updateTokens(token, refreshToken));
                }
                dispatch(storeAccountingExpenses(response.data));
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(setError(err.response.statusText));
                dispatch(stopLoading());
            });
    };
};
