import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import moekahLogo from "../../assets/images/moekah-logos-gruppe.png";
import Menu from "../../components/Menu/Menu";

import Spinner from "../../ui/Spinner";
import TempSuccess from "../../ui/TempSuccess";

import styles from "./AdminLayout.module.css";
import * as routes from "../../shared/routes";

const AdminLayout = ({ children }) => {
    const loading = useSelector((state) => state.main.loading);
    const authenticated = useSelector((state) => state.admin.token !== null);
    const privilegs = useSelector((state) => state.admin.user.privilegs);
    const expenses = useSelector((state) => state.admin.user.expenses);
    const name = useSelector((state) => state.admin.user.name);

    document.getElementsByTagName("body")[0].classList.add("admin");

    return (
        <Fragment>
            <TempSuccess />
            <div className={styles.topBar}>
                <Menu authenticated={authenticated} privilegs={privilegs} expenses={expenses} />
                <div className={styles.logo}>
                    <img src={moekahLogo} alt="Moekah Logo" />
                    <div>
                        <span>Version 2.2.2</span>
                        {authenticated && (
                            <>
                                 • <span>{name}</span> • 
                                <NavLink key={routes.LOGOUT} to={routes.LOGOUT}>
                                    Abmelden
                                </NavLink>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {children}
            {loading && <Spinner />}
        </Fragment>
    );
};

export default AdminLayout;
