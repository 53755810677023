import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { _clearSuccess } from "../store/actions/adminActions";

const TempSuccess = () => {
    const success = useSelector((state) => state.main.success);
    const dispatch = useDispatch();
    const clearSuccess = useCallback(() => dispatch(_clearSuccess()), [dispatch]);

    useEffect(() => {
        if (success) {
            window.setTimeout(() => {
                document.getElementById("tempSuccess").classList.add("show");
            }, 100);
            window.setTimeout(() => {
                document.getElementById("tempSuccess").classList.remove("show");
                window.setTimeout(clearSuccess, 300);
            }, 3000);
        }
    }, [success, clearSuccess]);

    if (success) {
        return (
            <div id="tempSuccess" className="success">
                {success}
            </div>
        );
    } else {
        return null;
    }
};

export default TempSuccess;
