export const LOGIN = "/login";
export const USERS = "/benutzer";
export const FORMS = "/forms";
export const FORM_ADMIN = "/formular-administration";
export const FAHRZEUG = "/Fahrzeug";
export const FAHRZEUG_REPARATUR = "/Fahrzeugreparatur";
export const FAHRZEUG_UNFALL = "/UnfallSchadensmeldung";
export const LSVA_KARTEN_AUSLESEN = "/LSVA_karten_auslesen";
export const WARTUNGSARBEITEN_KSG = "/WartungsarbeitenKSG";
export const WARTUNGSARBEITEN_KSG_TV = "/WartungsarbeitenKSG-TV";
export const WARTUNGSARBEITEN_LKW = "/WartungsarbeitenLKW";
export const WARTUNGSARBEITEN_WISCHER = "/WartungsarbeitenWischer";
export const FAHRZEUGAUSLEIHE = "/Fahrzeugausleihe";
export const FAHRZEUGRUECKGABE = "/Fahrzeugrueckgabe";
export const POOLFAHRZEUG_AUSLEIHE = "/VermietungPoolfahrzeug";
export const POOLFAHRZEUG_RUECKGABE = "/RueckgabePoolfahrzeug";

export const SAUGANHAENGER_AUSLEIHE = "/SauganhaengerAusleihen";
export const SAUGANHAENGER_RUECKGABE = "/SauganhaengerRueckgabe";

export const AKTENNOTIZ = "/Aktennotiz";
export const ARZTZEUGNIS = "/arztzeugnis";
export const FERIENANTRAG = "/Ferienantrag";
export const FOTOS_TECHNIKER = "/FotosTechniker";
export const GESPRAECH_MIT_PERSONALABTEILUNG = "/GespraechMitPersonalabteilung";
export const HAFTUNGSAUSSCHLUSS = "/Haftungsausschluss";
export const IDEENBOX = "/Ideenbox";
export const KLEIDERBESTELLUNG = "/KleiderBestellung";
export const PERSONEN_UNFALL = "/Unfall";
export const SCHAECHTE_ABSAUGEN = "/SchaechteAbsaugen";
export const TICKET_WALTER_ZOO = "/TicketWalterZoo";
export const ZUERI_ZOO_TICKETS = "/ZueriZooTickets";
export const CHECK = "/kontrolle";
export const FORMS_FINISH = FORMS + "/danke";
export const PIKETT = "/pikett";
export const TV_ERSATZTEILE = "/tv-ersatzteile";
export const FORM_DATA = "/formdaten";
export const NEUTRALISATIONSANLAGE = "/neutralisationsanlage";
export const LOGOUT = "/logout";
export const ERROR = "/fehler";

export const EXPENSES = "/spesen";
