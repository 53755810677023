import axios from "axios";

const instance = axios.create({
	baseURL: process.env.REACT_APP_AXIOS_URL
});

// Add a request interceptor
instance.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem("token");
		if (token) {
			config.headers["Authorization"] = "Bearer " + token;
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	function (error) {
		const originalRequest = error.config;
		if (originalRequest.url !== "admin/login" && error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			return axios
				.post(process.env.REACT_APP_AXIOS_URL + "admin/refreshToken", {
					refreshToken: localStorage.getItem("refreshToken")
				})
				.then((res) => {
					if (res.status === 201) {
						originalRequest.headers["Authorization"] = "Bearer " + res.data.token;
						originalRequest.updatedToken = { token: res.data.token, refreshToken: res.data.refreshToken };
						return axios(originalRequest);
					}
				});
		}
		// return Error object with Promise
		return Promise.reject(error);
	}
);

export default instance;
