export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const SUCCESSFULLY_SENT = "SUCCESSFULLY_SENT";
export const STORE_USER = "STORE_USER";
export const STORE_FORMS = "STORE_FORMS";
export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";

export const STORE_FORM = "STORE_FORM";
export const SET_REDIRECT = "SET_REDIRECT";
export const CLEAR_REDIRECT = "CLEAR_REDIRECT";
export const STORE_USER_ID = "STORE_USER_ID";
export const STORE_VEHICLES = "STORE_VEHICLES";
export const STORE_PERSONS = "STORE_PERSONS";
export const STORE_COMPANIES = "STORE_COMPANIES";
export const STORE_VEHICLE = "STORE_VEHICLE";
export const STORE_NOTIFICATION_USERS = "STORE_NOTIFICATION_USERS";
export const STORE_IMAGE = "STORE_IMAGE";

export const STORE_EXPENSES = "STORE_EXPENSES";
export const STORE_LARGE_IMAGE = "STORE_LARGE_IMAGE";
export const CLEAR_LARGE_IMAGE = "CLEAR_LARGE_IMAGE";
export const START_EDIT_EXPENSE = "EDIT_EXPENSE";
export const STOP_EDIT_EXPENSE = "STOP_EDIT_EXPENSE";
export const STORE_SUPERIORS = "STORE_SUPERIORS";

export const ADMIN_ADD_NOTIFICATION_USER_TO_FORM = "ADMIN_ADD_NOTIFICATION_USER_TO_FORM";
export const ADMIN_REMOVE_NOTIFICATION_USER_FROM_FORM = "ADMIN_REMOVE_NOTIFICATION_USER_FROM_FORM";

export const ADMIN_UPDATE_USER = "ADMIN_UPDATE_USER";
export const ADMIN_ADD_FORM_GROUP = "ADMIN_ADD_FORM_GROUP";
export const ADMIN_REMOVE_FORM_GROUP = "ADMIN_REMOVE_FORM_GROUP";
export const ADMIN_ADD_USER_TO_FORM_GROUP = "ADMIN_ADD_USER_TO_FORM_GROUP";
export const ADMIN_REMOVE_USER_FROM_FORM_GROUP = "ADMIN_REMOVE_USER_FROM_FORM_GROUP";
export const ADMIN_AUTH_LOGOUT = "ADMIN_AUTH_LOGOUT";
export const ADMIN_AUTH_UPDATE_TOKENS = "ADMIN_AUTH_UPDATE_TOKENS";
export const ADMIN_STORE_FORM_DATA = "ADMIN_STORE_FORM_DATA";
export const ADMIN_CLEAR_FORM_DATA = "ADMIN_CLEAR_FORM_DATA";
export const ADMIN_UPDATE_FORM_DATA = "ADMIN_UPDATE_FORM_DATA";
export const ADMIN_STORE_USERS = "ADMIN_STORE_USERS";
export const ADMIN_SET_USERDATA = "ADMIN_SET_USERDATA";
export const ADMIN_STORE_GROUPS = "ADMIN_STORE_GROUPS";
export const ADMIN_STORE_FORM_CATEGORIES = "ADMIN_STORE_FORM_CATEGORIES";
export const ADMIN_STORE_FORM = "ADMIN_STORE_FORM";
export const ADMIN_STORE_FORMS = "ADMIN_STORE_FORMS";

export const ADMIN_STORE_SUPERIOR_EXPENSES = "ADMIN_STORE_SUPERIOR_EXPENSES";

export const ADMIN_STORE_ACCOUNTING_EXPENSES = "ADMIN_STORE_ACCOUNTING_EXPENSES";
export const ADMIN_UPDATE_ACCOUNTING_EXPENSE = "ADMIN_UPDATE_ACCOUNTING_EXPENSE";
