import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import Spinner from "../../ui/Spinner";

const Layout = ({ children }) => {
    const loading = useSelector((state) => state.main.loading);
    return (
        <Fragment>
            <div id="form">{children}</div>
            {loading && <Spinner />}
        </Fragment>
    );
};

export default Layout;
